import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/dark";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Feature from "@onnit-js/ui/components/module/Feature";
import Box from "@onnit-js/ui/components/box/Box";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import Button from "@onnit-js/ui/components/button/Button";
import Text from "@onnit-js/ui/components/text";
import {Img} from "@onnit-js/ui/components/image";
import Dash from "@onnit-js/ui/components/icon/Dash";
import Embed from "@onnit-js/ui/components/video/Embed";

const seasons = [
    {
        slug: "six-hydrocore",
        title: "hydrocore",
        description: "A 6-week training program to build stability, strength, and power. Just add water."
    },
    {
        slug: "six-bodyweight",
        title: "bodyweight",
        description:
            "Workout anytime. Anywhere. You don’t have to buy any complicated fitness contraptions destined to be door stoppers. Just watch the videos follow along.",
    },
    {
        slug: "six-kettlebell",
        title: "kettlebell",
        description:
            "The world’s first-ever truly holistic kettlebell body transformation plan, using only a few kettlebells and some open floor space.",
    },
    {
        slug: "six-steel-mace",
        title: "steel mace",
        description:
            "Onnit 6 Steel Mace is a full-body, transformative workout you can do in the comfort of your own home in just six weeks. One steel mace, a stronger, leaner, more badass you.",
    },
    {
        slug: "six-barbell",
        title: "barbell",
        description:
            "Discover how a training tool you thought you knew can give you a better, safer workout than you’ve ever had—build muscle without heavy weights.",
    },
    {
        slug: "six-durability",
        title: "durability",
        description:
            "Improve joint mobility and flexibility, and learn to move pain-free, using a combination of bodyweight and steel club exercises.",
    },
    {
        slug: "six-steel-club",
        title: "steel club",
        description:
            "Onnit 6 Steel Club is a 6-week, total-body training program for building functional strength endurance using just a steel club and your own bodyweight.",
    },
];

function Page() {
    const onnitContext = useOnnitContext();

    if (!onnitContext) return null;

    const IMG_URL = onnitContext.url.cdn.static_images + "/onnit-6/list";
    const IMG_PARAMS = "?q=40&fm=pjpg&auto=compress,format";

    return (
        <ThemeProvider theme={theme}>
            <Box bg="black">
                <GlobalStyle />
                <Box display="flex" flexDirection="column" alignItems="center" pt={[8, 8, 10]} pb={[6, 6, 8]}>
                    <Dash color="sunwashGold" />
                    <Text as="h1" typeStyle="headlineHero02" my={[6, 6, 8]} textAlign="center">
                        THE ONNIT 6 SUITE
                    </Text>
                    <Box maxWidth="720px">
                        <Text typeStyle="copyHero01" textAlign="center" mb={[4, 4, 6]}>
                            Workout Online, Feel Great Offline.
                        </Text>
                        <Text as="p" typeStyle="copy02" textAlign="center">
                            Onnit 6 is a full-body, transformative workout you can do in the comfort of your own home in
                            just six weeks.
                        </Text>
                    </Box>
                </Box>

                <div className="hide-ie">
                    <Grid mb={[6, 6, 8]}>
                        <Box gridColumn={["1 / -1", "1/-1", "3 / span 8"]} px={4}>
                            <Embed title="Onnit 6 Product video" src="https://www.youtube.com/embed/RHgxaGNeF6o" />
                        </Box>
                    </Grid>

                    {seasons.map(({ slug, title, description }) => (
                        <Feature
                            px={4}
                            py={[6, 6, 6, 8]}
                            href={`/${slug}`}
                            heading={
                                <Text as="h2" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]}>
                                    {title}
                                </Text>
                            }
                            image={
                                <Img
                                    alt={`Onnit 6 ${title}`}
                                    src={`${IMG_URL}/${slug}.jpg${IMG_PARAMS}`}
                                    width={2368}
                                    height={1040}
                                />
                            }
                            cta={
                                <Button el="a" href={`/${slug}`} width={1}>
                                    Start Now
                                </Button>
                            }
                            content={
                                <Text as="p" typeStyle="body">
                                    {description}
                                </Text>
                            }
                        />
                    ))}
                </div>
            </Box>
        </ThemeProvider>
    );
}

export default Page;
